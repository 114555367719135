import { FC, useEffect, useState, } from 'react'
import { Checkbox } from '../../../../../../_metronic/partials/forms/Checkbox'
import { cloneDeep } from "lodash";
import { useServiceRunsContext } from '../../../context/ServiceRuns-context';
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { IconButton } from '@mui/material';

export interface RoleCheckboxInterface {
    form: {
        touched: {},
        initialValues: { jsonID: { [key: string]: string } },
        errors: { jsonID: { [key: string]: string[] } },
        setFieldTouched: (a: string, b: boolean) => {},
        setFieldValue: (a: string, b: {}) => {},
        values: { jsonID: { [key: string]: (string | null) | Array<{ [key: string]: string | null }> | null | string[] }, serviceID: string }
    },
    rowParams: {
        value: string | number | string[] | number[],
        row: { can_click: boolean; service_descriptive_name: string; }
    } | null,
    columnParams: GridColumnHeaderParams | null,
    checkboxPropName: string,
    identityparams: string,
    data: any
}

export const RoleCheckbox: FC<RoleCheckboxInterface> = ({
    form,
    rowParams,
    columnParams,
    checkboxPropName,
    identityparams,
    data
}) => {

    const { records, setRecords } = useServiceRunsContext()
    const [allSelected, setAllSelected] = useState(false);
    const [someSelected, setSomeSelected] = useState(false);

    useEffect(() => {
        if (data && "tabledata" in data && columnParams) {
            setAllSelected(records.every(row => row[columnParams.field] === 'Y'));
            setSomeSelected(records.some(row => row[columnParams.field] === 'Y') && records.some(row => row[columnParams.field] === 'N'));
        }
    }, [records, data])

    const checkAction = (identityObject: { [key: string]: string }, event: React.ChangeEvent<HTMLInputElement>, checkboxPropName: string) => {
        event.stopPropagation();
        if (Array.isArray(records)) {
            let localCopy = cloneDeep(records)

            if (rowParams) {
                // find selected row from the table based on identityparams
                const selectedEntry = localCopy.filter((entry: { [key: string]: string | null }) =>
                    identityparams.split(",").every(param => entry[param] === identityObject[param])
                )
                if (selectedEntry && selectedEntry.length > 0) {
                    selectedEntry[0][checkboxPropName] = event.target.checked ? "Y" : 'N'
                }
            } else {
                if (allSelected) {
                    localCopy.forEach(row => row[checkboxPropName] = 'N');
                } else {
                    localCopy.forEach(row => row[checkboxPropName] = 'Y');
                }
            }
            // if deselect module_access we deselect email_notification
            // if (checkboxPropName === "module_access" && !event.target.checked) {
            //   selectedEntry[0]['email_notification'] = 'N';
            // }
            // if select email_notification we select module_access
            // if (checkboxPropName === "email_notification" && event.target.checked) {
            //   selectedEntry[0]['module_access'] = "Y";
            // }
            setRecords(localCopy)
            // check for each row in the table data if it id different from initial data and push it in array
            let roleTableArray: Array<{ [key: string]: string | null; }> = [];
            localCopy.forEach((row, index) => {
                if (data && "tabledata" in data && JSON.stringify(row) !== JSON.stringify(data.tabledata[index])) {
                    let localRow = cloneDeep(row)
                    // create action property which determine if the operation is insert or update
                    if (data.tabledata[index]['user_exists'] === 'Y') localRow['action'] = 'update'
                    else localRow['action'] = 'insert'
                    roleTableArray.push(localRow)
                }
            })
            console.log(roleTableArray);
            form.setFieldValue('jsonID.RoleTable', roleTableArray)
        }
    }

    return (
        <>
            {rowParams
                ? <Checkbox
                    checked={rowParams?.value === 'Y' ? true : false}
                    //checkbox prop name
                    checkboxPropName={checkboxPropName}
                    disabled={false}
                    // identityObject uniquely identified each row in the table like composite primary key
                    identityObject={identityparams.split(",").reduce((accumulator, currentValue) => ({ ...accumulator, [currentValue]: rowParams?.row[currentValue as keyof typeof rowParams.row] }), {})}
                    checkAction={checkAction}
                />
                : <Checkbox
                    checked={allSelected}
                    //checkbox prop name
                    checkboxPropName={checkboxPropName}
                    disabled={false}
                    // identityObject uniquely identified each row in the table like composite primary key
                    identityObject={{ checkboxPropName }}
                    checkAction={checkAction}
                    indeterminate={someSelected}
                />
            }
        </>
    )
}