import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { useServiceRedux } from '../../../../../app/modules/ServiceRuns/redux/useServiceRedux'
import { useLocation } from 'react-router-dom';
import { serviceConfig } from '../../../../../app/modules/ServiceRuns/redux/cloudAppAPI'
import { useAppSelector } from "../../../../../app/redux/store";
import { useGlobalContext } from '../../../../../app/components/GlobalContext/GlobalContext'
import React from 'react';
// import { useIsAllowedServiceInBu } from '../../../../../app/hooks/useGetBU'
export const menus = [
  {
    services: [
      {
        path: "ManualSubmission",
        name: "Manual Submission",
        cardLogoClasses: "fas fa-user-gear text-dark bg-homepage-logo"
      },
      {
        path: "SubmissionApproval",
        name: "Submission Approval",
        cardLogoClasses: "fas fa-user-gear text-dark bg-homepage-logo"
      }
    ],
    isBURestricted: true,
    title: 'Data Submission'
  },
  {
    services: [
      {
        path: "MonthEndClose",
        name: "Month End Close",
        cardLogoClasses: "fas fa-user-gear text-dark bg-homepage-logo"
      },
      // {
      //   path: "ProfitCenterMapping",
      //   name: "Profit Center Mapping",
      //   cardLogoClasses: "fas fa-user-gear text-dark bg-homepage-logo"
      // },
      {
        path: "CorporateVolumeExtract",
        name: "Corporate Volume Extract",
        cardLogoClasses: "fas fa-user-gear text-dark bg-homepage-logo"
      }
    ],
    isBURestricted: true,
    title: 'Monthly Close'
  },
  {
    services: [
      {
        path: "SourceMixedPackProduct",
        name: "Source Mixed Pack Product",
        cardLogoClasses: "fas fa-user-gear text-dark bg-homepage-logo"
      },
      {
        path: "BUMixedPackProduct",
        name: "BU Mixed Pack Product",
        cardLogoClasses: "fas fa-user-gear text-dark bg-homepage-logo"
      }
    ],
    isBURestricted: true,
    title: 'Master Data'
  },
  {
    services: [
      {
        path: "BusinessPlan",
        name: "Business Plan",
        cardLogoClasses: "fas fa-user-gear text-dark bg-homepage-logo"
      },
      {
        path: "RollingEstimates",
        name: "Rolling Estimates",
        cardLogoClasses: "fas fa-solid fa-group-arrows-rotate text-dark fa-stack-5x bg-homepage-logo"
      },
      {
        path: "DailyWeight",
        name: "Daily Weight",
        cardLogoClasses: "fas fa-solid fa-group-arrows-rotate text-dark fa-stack-5x bg-homepage-logo"
      },
      {
        path: "NonWorkingDays",
        name: "Non Working Days",
        cardLogoClasses: "fas fa-solid fa-group-arrows-rotate text-dark fa-stack-5x bg-homepage-logo"
      },
      {
        path: "LocalPopulation",
        name: "Local Population",
        cardLogoClasses: "fas fa-solid fa-group-arrows-rotate text-dark fa-stack-5x bg-homepage-logo"
      },
      {
        path: "LocalHierarchy",
        name: "Local Hierarchy",
        cardLogoClasses: "fas fa-solid fa-group-arrows-rotate text-dark fa-stack-5x bg-homepage-logo"
      },
      {
        path: "LocalConversionFactor",
        name: "Local Conversion Factor",
        cardLogoClasses: "fas fa-solid fa-group-arrows-rotate text-dark fa-stack-5x bg-homepage-logo"
      },
      {
        path: "ManualEstimates",
        name: "Manual Estimates",
        cardLogoClasses: "fas fa-solid fa-group-arrows-rotate text-dark fa-stack-5x bg-homepage-logo"
      },
      {
        path: "RegionalHierarchy",
        name: "Regional Hierarchy",
        cardLogoClasses: "fas fa-solid fa-group-arrows-rotate text-dark fa-stack-5x bg-homepage-logo"
      }
    ],
    isBURestricted: true,
    title: 'Other Sources'
  },
  {
    services: [
      {
        path: "ServiceMonitor",
        name: "Service Monitor",
        cardLogoClasses: "fas fa-user-gear text-dark bg-homepage-logo"
      },
      {
        path: "CreateDBUser",
        name: "Request User Access",
        cardLogoClasses: "fas fa-user-gear text-dark bg-homepage-logo"
      },
      {
        path: "ModifyUserAccess",
        name: "Modify User Access(New API)",
        cardLogoClasses: "fas fa-user-gear text-dark bg-homepage-logo"
      },
      {
        path: "CubeRefreshLauncher",
        name: "Cube Refresh Launcher",
        cardLogoClasses: "fas fa-cube text-dark fa-stack-5x bg-homepage-logo"
      },
      {
        path: "FinancialViewSync",
        name: "Financial View Sync",
        cardLogoClasses: "fas fa-solid fa-group-arrows-rotate text-dark fa-stack-5x bg-homepage-logo"
      },
      {
        path: "FlatFileGenerator",
        name: "Transaction Flat File Generation",
        cardLogoClasses: "fas fa-regular fa-file text-dark bg-homepage-logo"
      },
      {
        path: "BulkCodeRemapping",
        name: "Bulk Code Remapping",
        cardLogoClasses: "fas fa-filter-circle-dollar text-dark bg-homepage-logo"
      }
    ],
    isBURestricted: false,
    title: 'Data Steward Panel'
  }
]


export function MenuInner() {
  const { serviceConfigNames } = useServiceRedux();
  const location = useLocation();
  const { bu } = useGlobalContext();
  const { data } = useAppSelector((state) => serviceConfig(state))
  const isAllowedServiceInBu = (services: string[]) => {
    return data && services.some(service => data.filter(entry => entry.service_descriptive_name === service).length > 0 && data.filter(entry => entry.service_descriptive_name === service)[0].buConfig.map(entry => entry.value).includes(bu))
  }

  return (
    <>
      {/* <MenuInnerWithSub title='Request Services' to='/services/createservicerun/' menuPlacement='bottom-start' menuTrigger='click' hasArrow={true}> */}
      {/* PAGES */}
      {/* <MenuInnerWithSub
          title='User Management'
          to='/apps/chat'
          // icon='/media/icons/duotune/communication/com012.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          
        </MenuInnerWithSub> */}
      {/* <MenuItem to='/services/createservicerun/CreateDBUser' title='Request User Access' hasBullet={true} disabled={!serviceConfigNames?.includes("CreateDBUser") || serviceConfigNames === undefined} />
        <MenuItem to={`${serviceConfigNames?.includes("CubeRefreshLauncher") ? '/services/createservicerun/CubeRefreshLauncher' : '#'}`} title='CubeRefreshLauncher' hasBullet={true} disabled={!serviceConfigNames?.includes("CubeRefreshLauncher") || serviceConfigNames === undefined} />
        <MenuItem to={`${serviceConfigNames?.includes("FinancialViewSync") ? '/services/createservicerun/FinancialViewSync' : '#'}`} title='Financial View Sync' hasBullet={true} disabled={!serviceConfigNames?.includes("FinancialViewSync") || serviceConfigNames === undefined} />
        <MenuItem to={`${serviceConfigNames?.includes("FlatFileGenerator") ? '/services/createservicerun/FlatFileGenerator' : '#'}`} title='Transaction Flat File Generation' hasBullet={true} disabled={!serviceConfigNames?.includes("FlatFileGenerator") || serviceConfigNames === undefined} /> */}
      {/* <MenuItem
          icon='/media/icons/duotune/general/gen051.svg'
          to='/apps/user-management/users'
          title='User management'
        /> */}
      {/* </MenuInnerWithSub> */}
      {/* <MenuItem title={"Monitor"} to='#' disabled={true} /> */}
      {/* <MenuItem title={"List Services"} to='/services'  disabled={false}/> */}
      {/* <MenuItem title={"Data Submission"} to='/synapse' disabled={false} />
      <MenuItem to='/services/listservicerun/' title='Monthly Close' disabled={false} />
      <MenuItem to='/services/listservicerun/' title='Master Data' disabled={false} /> */}
      <MenuItem to='/services/listdmm/' title='Monitor' disabled={false} />
      {
        menus.map(menuItem => {
          const menuAllowed = menuItem.isBURestricted ? isAllowedServiceInBu(menuItem.services.map(service => service.path)) :
            serviceConfigNames?.some(ser => menuItem.services.map(service => service.path).includes(ser))
          return (
            <React.Fragment key={menuItem.title}>
              {menuAllowed &&
                <MenuInnerWithSub title={menuItem.title} to={menuItem.services.some(service => location.pathname.includes(service.path)) ? location.pathname : "#"} menuPlacement='bottom-start' menuTrigger='click' hasArrow={true}>
                  {menuItem.services.map(service => {
                    const subMenuAllowed = menuItem.isBURestricted ? isAllowedServiceInBu([service.path]) : serviceConfigNames?.includes(service.path)
                    return (
                      <div key={service.name}>
                        {service.path === "ServiceMonitor" && <MenuItem to={`/services/listservicerun/`} title={`${service.name}`} hasBullet={true} disabled={false} />}
                        {subMenuAllowed  && <MenuItem to={`/services/createservicerun/${service.path}`} title={`${service.name}`} hasBullet={true} disabled={false} />}
                      </div>
                    )
                  })}
                </MenuInnerWithSub>
              }
            </React.Fragment>
          )
        })
      }
      {/* {serviceConfigNames?.some(ser => otherSources.includes(ser)) &&
        // (data.filter(entry => entry.service_descriptive_name === "BusinessPlan")[0].buConfig.map(entry => entry.value).includes(bu) ||
        // data.filter(entry => entry.service_descriptive_name === "RollingEstimates")[0].buConfig.map(entry => entry.value).includes(bu)) &&
        (bpAllowed || reAllowed) &&
        <MenuInnerWithSub title='Other Sources' to={otherSources.some(service => location.pathname.includes(service)) ? location.pathname : "#"} menuPlacement='bottom-start' menuTrigger='click' hasArrow={true}>
          {bpAllowed && serviceConfigNames?.includes("BusinessPlan") && <MenuItem to='/services/createservicerun/BusinessPlan' title='Business Plan' hasBullet={true} disabled={false} />}
          {reAllowed && serviceConfigNames?.includes("RollingEstimates") && <MenuItem to='/services/createservicerun/RollingEstimates' title='Rolling Estimate' hasBullet={true} disabled={false} />}
        </MenuInnerWithSub>}
      {serviceConfigNames?.some(ser => dataStewardPanel.includes(ser)) && <MenuInnerWithSub title='Data Steward Panel' to={dataStewardPanel.some(service => location.pathname.includes(service)) ? location.pathname : "#"} menuPlacement='bottom-start' menuTrigger='click' hasArrow={true}>
        {serviceConfigNames?.includes("CreateDBUser") && <MenuItem to='/services/createservicerun/CreateDBUser' title='Request User Access' hasBullet={true} disabled={false} />}
        {serviceConfigNames?.includes("CubeRefreshLauncher") && <MenuItem to='/services/createservicerun/CubeRefreshLauncher' title='Cube Refresh Launcher' hasBullet={true} disabled={false} />}
        {serviceConfigNames?.includes("FinancialViewSync") && <MenuItem to='/services/createservicerun/FinancialViewSync' title='Financial View Sync' hasBullet={true} disabled={false} />}
        {serviceConfigNames?.includes("FlatFileGenerator") && <MenuItem to='/services/createservicerun/FlatFileGenerator' title='Transaction Flat File Generation' hasBullet={true} disabled={false} />}
        {serviceConfigNames?.includes("BulkCodeRemapping") && <MenuItem to='/services/createservicerun/BulkCodeRemapping' title='Bulk Code Remapping' hasBullet={true} disabled={false} />}
      </MenuInnerWithSub>} */}

    </>
  )
}
