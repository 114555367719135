import { useEffect, useState } from "react";
import { useSetErrorMessage } from "../../../hooks/useSetErrorMessage"
import { useServiceRunsContext } from '../../../modules/ServiceRuns/context/ServiceRuns-context'

export const useSetPreQuery = (setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void, user_exist: boolean) => {

  useEffect(() => {
    setFieldValue("jsonID.preQuery", !user_exist)

  }, [user_exist])
}

export const getFieldValue = (value: string | { [key: string]: string | null } | Array<{ [key: string]: string | null }> | null | string[]): string | { [key: string]: string | null } | Array<{ [key: string]: string | null }> | null | string[] => {
  let returnedValue = value
  if (value instanceof Object && !(value instanceof Array)) returnedValue = value.value
  return returnedValue
}

export const useCheckQueryReady = (
  form: {
    initialValues: { jsonID: { [key: string]: string } },
    errors: { jsonID: { [key: string]: string[] } },
    values: { jsonID: { [key: string]: (string | null) | Array<{ [key: string]: string | null }> | null | string[] }, serviceID: string }
  },
  queryparams: string,
) => {
  const { currentServiceConfig, editTable } = useServiceRunsContext();
  const [notPopulatedFieldsArrayState, setNotPopulatedFieldsArrayState] = useState<Array<string>>([])
  const [queryValuesObject, setQueryValuesObject] = useState<{ [key: string]: string }>({})
  const [queryReady, setQueryReady] = useState(false)

  useEffect(() => {
    if (queryparams) {
      const queryParamsArray = queryparams.split(',')
      let queryReadyLocal = true;
      let notPopulatedFieldsArray: string[] = [];
      let queryValuesObjectLocal: { [key: string]: string } = {};
      queryParamsArray.forEach(param => {
        // stop DB query on errors in some of queryparams
        if (form.errors && form.errors.jsonID && param in form.errors.jsonID) {
          queryReadyLocal = false;
        }
        // this case is when the form is initially opened because there are no errors on this moment
        // this case is also when the user open processed request
        const currentFieldConfig = currentServiceConfig?.field_config?.filter(field => field.id === param)
        if (form.initialValues.jsonID[param] === form.values.jsonID[param] && currentFieldConfig.length > 0 &&
          currentFieldConfig[0].type !== "automatic" && currentFieldConfig[0].type !== "Global" && !editTable) {
          queryReadyLocal = false;
        }
        if (form.values.jsonID[param] === "" || form.values.jsonID[param] === "default" || form.values.jsonID[param] === undefined) {
          queryReadyLocal = false;
        }
        if(Array.isArray(form.values.jsonID[param]) && form.values.jsonID[param]!.length === 0) {
          queryReadyLocal = false;
        }
        if (queryReadyLocal) {
          queryValuesObjectLocal[param] = getFieldValue(form.values.jsonID[param]) as string
        } else {
          if (currentFieldConfig.length > 0 && currentFieldConfig[0].type !== "automatic") {
            notPopulatedFieldsArray.push(currentFieldConfig[0].label)
          }
        }
      })
      setNotPopulatedFieldsArrayState(notPopulatedFieldsArray)
      if (queryReadyLocal) {
        setQueryValuesObject(queryValuesObjectLocal)
        setQueryReady(true)
      } else {
        setQueryReady(false)
      }
    }
  }, [form.values, form.errors, currentServiceConfig])

  return {
    notPopulatedFieldsArrayState,
    queryValuesObject,
    queryReady
  }
}

export const useTriggerDBQuery = (
  name: string,
  form: {
    initialValues: { jsonID: { [key: string]: string } },
    errors: { jsonID: { [key: string]: string[] } },
    values: { jsonID: { [key: string]: (string | null) | Array<{ [key: string]: string | null }> | null | string[] }, serviceID: string }
  },
  queryparams: string,
  type: string,
) => {

  const { trigger, result } = useServiceRunsContext();
  const { queryValuesObject, queryReady } = useCheckQueryReady(form, queryparams)

  useEffect(() => {
    if (queryReady) {
      trigger({ queryParams: queryValuesObject, service: form.values.serviceID, fieldName: name.replace('jsonID.', ''), type: type })
    }
  }, [queryReady, JSON.stringify(queryValuesObject)])


  const { data = [], error, isFetching } = result;
  useSetErrorMessage([{ error: error, function: "AsyncDBAccess" }])
  return {
    data: data,
    isFetching,
    error
  }
}