import React, { FC } from "react";
import { useEffect, useState } from "react";
import { FieldConfigInterface } from '../interfaces/serviceRunsInterfaces'
import { useServiceRunsContext } from "../context/ServiceRuns-context";
import { DGDatePicker } from "../../../../_metronic/partials/forms/DGDatePicker";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { Formik, Form, Field } from "formik";
import { useParams } from "react-router-dom";
import { HistoryButton, HistoryButtonInterface } from "../../../../_metronic/partials";
import { RoleCheckbox } from "../components/CreateServiceRun/RoleTable/RoleCheckbox";
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import clsx from "clsx";

interface inputMapInterface {
    [key: string]:
    FC<HistoryButtonInterface>
}

const inputMap: inputMapInterface = {
    HistoryButton: HistoryButton,
}

interface ButtonConfInterface {
    label: string;
    id: string;
    type: keyof typeof inputMap;
    queryparams: string;
    state: any;
    nm_ptrn: any;
    showInEdit: boolean;
    showInNew: boolean;
    inTable: boolean;
    tableValues: { id: string, label: string, monitored: boolean }[];
    modalTopic: string;
    action: string;
    color: string;
}

const DynamicTableService: FC<{
    buttonconf: ButtonConfInterface,
    params: any,
    form: any
}> = ({
    buttonconf,
    params,
    form
}) => {

        const { serviceid } = useParams();

        return (
            <>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ serviceID: serviceid, jsonID: { ...form.values.jsonID, ...params.row } }}
                    // validationSchema={RequestEditSchema}
                    onSubmit={() => { }}
                >
                    {formik => {
                        return (
                            <Form className="form form-label-right">
                                <>
                                    <div className="d-flex">
                                        <div key={buttonconf.id}>
                                            <Field
                                                label={buttonconf.label}
                                                id={buttonconf.id}
                                                component={inputMap[buttonconf.type]}
                                                queryparams={buttonconf.queryparams}
                                                nm_ptrn={buttonconf.nm_ptrn}
                                                showInEdit={buttonconf.showInEdit}
                                                showInNew={buttonconf.showInNew}
                                                inTable={buttonconf.inTable}
                                                tableValues={buttonconf.tableValues}
                                                modalTopic={buttonconf.modalTopic}
                                                action={buttonconf.action}
                                                color={buttonconf.color}
                                            />
                                        </div>
                                    </div>
                                </>
                            </Form>
                        )
                    }}
                </Formik >
            </>
        )
    }

const renderCellMapper = (
    dl: FieldConfigInterface,
    params: {
        value: string | number | string[] | number[],
        row: { can_click: boolean; service_descriptive_name: string; }
    },
    form: any,
    identityparams: string | undefined,
    data: any
) => {
    switch (dl.id) {
        case 'mdm_updt_flag':
            if (params.value == 'Y') {
                return <AcUnitIcon fontSize='medium' sx={{ color: '#6ac9ce' }} />;
            } else {
                return '';
            }
        case 'history':
            return <DynamicTableService buttonconf={dl as any} params={params} form={form} />
        case 'module_access':
            return <RoleCheckbox
                form={form}
                rowParams={params}
                columnParams={null}
                checkboxPropName={dl.id}
                identityparams={identityparams || ''}
                data={data}
            />
        case 'email_notification':
            return <RoleCheckbox
                form={form}
                rowParams={params}
                columnParams={null}
                checkboxPropName={dl.id}
                identityparams={identityparams || ''}
                data={data}
            />
        default:
            return params.value;
    }
}


export const useCreateColumnsObject = (
    data_fields: FieldConfigInterface[],
    form: any,
    identityparams?: string,
    data?: any,
    dynamicHeaderExceptions: string[] | undefined = undefined,
    funcData: { [key: string]: string; }[] | undefined = undefined,
    minDateRestriction?: {
        property: string,
        query: string,
        depProp: string
    },
    maxDateRestriction?: string,
) => {
    const { setColumns, records } = useServiceRunsContext();

    // clear columns when switching between different table components
    useEffect(() => {

        setColumns([])
    }, [])

    useEffect(() => {
        if (data_fields?.length > 0) {
            setColumns(data_fields.filter(dl => dl.inTable === true).map(dl => ({
                field: dl.id,
                headerName: dl.label,
                sortable: dl.id === 'module_access' || dl.id === 'email_notification' ? false : true,
                minWidth: dl.id == 'history' ? 70 : 100,
                maxWidth: dl.id == 'history' ? 70 : window.innerWidth,
                disableExport: dl.disableExport,
                flex: 1,
                renderHeader: (params: GridColumnHeaderParams) => {
                    if (dl.id === 'module_access' || dl.id === 'email_notification') {
                        return (
                            <>
                                <RoleCheckbox
                                    form={form}
                                    rowParams={null}
                                    columnParams={params}
                                    checkboxPropName={dl.id}
                                    identityparams={identityparams || ''}
                                    data={data}
                                />
                                <span style={{marginLeft: '10px'}}>{dl.label}</span>
                            </>
                        )
                    } else {
                        return dl.label
                    }
                },
                headerClassName: 'MuiDataGrid-columnHeaderTitle',
                renderCell: (params: { value: string | number | string[] | number[], row: { can_click: boolean; service_descriptive_name: string; } }) =>
                    Array.isArray(params.value)
                        ? <div>
                            {params.value.map((item, index) => (
                                <React.Fragment key={index}>
                                    <span>{item}</span>
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
                        : renderCellMapper(dl, params, form, identityparams, data)
            })))
        }
    }, [data_fields, form.values.jsonID, records])

    useEffect(() => {

        if (dynamicHeaderExceptions && dynamicHeaderExceptions?.length > 0 && funcData && funcData.length > 0) {

            setColumns(Object.keys(funcData[0]).filter(key => !dynamicHeaderExceptions.includes(key)).map(key => ({
                field: key,
                headerName: key,
                sortable: true,
                minWidth: 210,
                flex: 1,
                renderCell: (params: { value: string | number | string[] | number[], field: string }) =>
                    !isNaN(+params.field.split(" ")[0]) ?
                        <>
                            <Field
                                component={DGDatePicker}
                                params={params}
                                minDateRestriction={minDateRestriction ? minDateRestriction : undefined}
                                maxDateRestriction={maxDateRestriction ? maxDateRestriction : undefined}
                            />
                        </> : params.value
            })))
        }

    }, [dynamicHeaderExceptions, funcData])
}

export const useGetColumnInitialState = (data_fields: FieldConfigInterface[]) => {

    const [initialState, setInitialState] = useState<{ columns: { columnVisibilityModel: { [key: string]: boolean } } }>({
        columns: {
            columnVisibilityModel: {},
        },
    });

    useEffect(() => {
        if (data_fields?.length > 0) {
            setInitialState(prev => {
                const current = { ...prev }
                data_fields.filter(dl => dl.inTable === true).forEach(f => current.columns.columnVisibilityModel[f.id] = f.shown)
                return current
            })
        }

    }, [data_fields])

    return initialState;
}