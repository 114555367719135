import { FC } from 'react';
import { useServiceRunsContext } from '../../../app/modules/ServiceRuns/context/ServiceRuns-context';

export interface RowButtonInterface {
    form: {
        values: { jsonID: { [key: string]: string | Date } },
        dirty: boolean,
        setFieldValue: (a: string, b: Date | string | number | Array<string | number | { [key: string]: string | Date }>) => {},
    },
    state: {},
    label: string,
    isLoading: boolean,
    showInEdit: boolean,
    showInNew: boolean,
    action: string,
    color: string,
    disabled: boolean
}


export const RowButton: FC<RowButtonInterface> = ({
    label,
    isLoading = false,
    form: { dirty, values, setFieldValue },
    action,
    color,
    disabled
}) => {
    const { records, setRecords } = useServiceRunsContext();
    const { commonQueries } = useServiceRunsContext();
    const onClick = () => {
        const date = commonQueries?.Months.find(item => item.value === values.jsonID.closeMonth)?.currentkey
        let fieldValue = Array.isArray(values.jsonID?.newDates) ? [...values.jsonID?.newDates] : [];
        setRecords(prev => prev.map(row => {
            const newDate = { datetimeClose: values.jsonID.day as Date, shipFrom: row.sec_cd, month: date }
            let oldValues;
            if (Array.isArray(fieldValue)) {
                oldValues = fieldValue.filter(item => !(item.shipFrom === row.sec_cd && item.month === date))
            }
            // setFieldValue("jsonID.newDates", oldValues ? [...oldValues, newDate] : [newDate])
            fieldValue = oldValues ? [...oldValues, newDate] : [newDate]
            const here = { ...row, [date!]: (values.jsonID.day as Date).toISOString() };
            return (here)
        }))
        if(fieldValue) setFieldValue("jsonID.newDates", fieldValue)
    }

    return (
        <>
            {label && <label style={{ visibility: 'hidden' }} className="fw-bolder font-size-h4 label-custom mb-2">{label}</label>}
            <div className="input-group">
                <button
                    type='button'
                    className={`btn btn-color-${color ? color : "success"} btn-${color ? color : "success"} text-white btn-sm me-5`}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    style={{ paddingBlock: '0.75rem', paddingInline: '4rem', fontSize: '1.3rem' }}
                    onClick={() => onClick()}
                    disabled={disabled || !records || records?.length === 0}
                >
                    <div className="d-flex align-items-center">
                        <span>{label}</span>
                        {isLoading && (
                            <span className="spinner-border spinner-border-sm text-light ms-2"></span>
                        )}
                    </div>
                </button>
            </div>
        </>
    );
}