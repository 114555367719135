import { FC } from 'react'
import CheckboxMUI from '@mui/material/Checkbox';
interface CheckboxInterface {
  checked: boolean,
  disabled: boolean,
  identityObject: {},
  checkAction: (identityObject:{},event: React.ChangeEvent<HTMLInputElement>, checkboxPropName: string) => void,
  checkboxPropName: string,
  indeterminate?: boolean
} 
export const Checkbox: FC<CheckboxInterface> = ({
  checked, 
  disabled, 
  identityObject, 
  checkAction, 
  checkboxPropName,
  indeterminate
}) => {
 
  return (
    <label className={`checkbox ${disabled ? "checkbox-disabled" : ""}`}>
      <CheckboxMUI
        checked={checked}
        indeterminate={indeterminate}
        onChange={(event) => {
          event.stopPropagation();
          checkAction(identityObject, event, checkboxPropName);
        }}
        disabled={disabled} />
      <span />
    </label>
  );
}