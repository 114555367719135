// import {
//   useGridApiContext,
//   GridRenderEditCellParams
// } from '@mui/x-data-grid';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import InputBase, { InputBaseProps } from '@mui/material/InputBase';
// import { styled } from '@mui/material/styles';
// import { TextFieldProps } from '@mui/material/TextField';
import { FC, useEffect, useState } from 'react';
import { getIn } from "formik";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { enUS as locale } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { getFieldHelpInterface, PopoverHelper } from './PopoverHelper';
import { useServiceRunsContext } from '../../../app/modules/ServiceRuns/context/ServiceRuns-context';

// const getFieldCSSClasses = (touched: {}, errors: {}) => {
//   const classes = ["form-control p-0"];
//   if (touched && errors) {
//     classes.push("is-invalid");
//   }

//   if (touched && !errors) {
//     classes.push("is-valid");
//   }

//   return classes.join(" ");
// };

const requiredFieldStyle = (req: boolean) => {
  return req ? <i className="fa fa-star-of-life icon-xs text-danger ms-1"></i> : null
}

// const GridEditDateInput = styled(InputBase)({
// //   fontSize: 'inherit',
// //   padding: '0 9px',
// });

// function WrappedGridEditDateInput(props: TextFieldProps) {
//   const { InputProps, focused, ...other } = props;
//   return (
//     <InputBase fullWidth {...InputProps} {...(other as InputBaseProps)} />
//   );
// }

export interface DGDatePickerInterface {
  field: {
    name: string,
    value: Date
  },
  form: {
    touched: {},
    errors: {},
    setFieldTouched: (a: string, b: boolean) => {},
    setFieldValue: (a: string, b: null | Date | string | number | Array<string | number | { [key: string]: string | Date }>) => {},
    values: { serviceID: string, jsonID: { [key: string]: string | { [key: string]: string | Date }[] } },
  },
  disabled: boolean,
  placeholder: string,
  novalidation: boolean,
  params: { row: { [key: string]: string }, field: string, value: string },
  label: string,
  required: boolean,
  help?: getFieldHelpInterface,
  minDateRestriction: {
    property: string,
    query: string,
    depProp: string
  },
  maxDateRestriction: string
}

export const DGDatePicker: FC<DGDatePickerInterface> = ({
  field,
  form: { touched, errors, setFieldTouched, setFieldValue, values },
  disabled,
  placeholder,
  params,
  label,
  required,
  help,
  minDateRestriction,
  maxDateRestriction
}) => {
  const themeMode = localStorage.getItem("kt_theme_mode_value");
  const errorMessage = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  const { commonQueries } = useServiceRunsContext();

  const [date, setDate] = useState<Date | null>(null);
  const [minDate, setMinDate] = useState<Date | undefined>();
  const [maxDate, setMaxDate] = useState<Date | undefined>();

  const handleChange = (newValue: unknown) => {

    if (params) {
      const newDate = { datetimeClose: newValue as Date, shipFrom: params.row.sec_cd, month: params.field }
      let oldValues;
      if (Array.isArray(values.jsonID?.newDates)) {
        oldValues = values.jsonID?.newDates.filter(item => !(item.shipFrom === params.row.sec_cd && item.month === params.field))
      }
      setFieldValue("jsonID.newDates", oldValues ? [...oldValues, newDate] : [newDate])
    } else {
      setFieldValue(field.name, newValue as Date)
    }
    setDate(newValue as Date)
  };

  function addDaysToDate(date: string | number | Date, days: number) {
    let result = new Date(date);  // Create a new Date object to avoid mutating the original one
    result.setDate(result.getDate() + days);  // Add the specified number of days
    return result;
  }

  useEffect(() => {
    if (minDateRestriction) {
      if (commonQueries && minDateRestriction.query in commonQueries) {
        if (params) {
          const element = commonQueries[minDateRestriction.query].find(item => item.currentkey === params.field)
          if (element) {
            setMinDate(new Date(element[minDateRestriction.property]))
          }
        } else {
          const element = commonQueries[minDateRestriction.query].find(item => item.value === values.jsonID[minDateRestriction.depProp])
          if (element) {
            setMinDate(new Date(element[minDateRestriction.property]))
          }
        }
      }
    }
    if (maxDateRestriction && minDate) {
      setMaxDate(addDaysToDate(minDate, +maxDateRestriction))
    }

  }, [commonQueries, values.jsonID])

  const fixDateString = () => {
    return typeof params?.value === 'string' ?
      (params.value[params.value.length - 1] === 'Z' ? params.value : (params.value + 'Z')) :
      params.value
  }

  useEffect(() => {
    if (field.name && values.jsonID[field.name.replace("jsonID.", "")] === null) {
      setDate(null)
    }

  }, [values.jsonID])

  return (
    <>
      {label && <label className="fw-bolder font-size-h4 label-custom mb-2">{label}{requiredFieldStyle(required)}</label>}
      <div className="input-group ">
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
          <DateTimePicker
            value={params ?
              (date ?? new Date(fixDateString()))
              : (date ?? null)}
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
            sx={{
              '&.MuiTextField-root': {
                flex: '1 1 0'
              },
              '& .MuiInputBase-input': {
                padding: "1rem 0.5rem 1rem 1rem"
              },
              '& .MuiInputBase-root': {
                '& .Mui-disabled': {
                  backgroundColor: '#eff2f5',
                },
                '&.MuiOutlinedInput-root': {
                  '&.Mui-disabled': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#E4E6EF',
                    }
                  },
                  '&.Mui-focused': {

                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#B5B5C3',
                      borderWidth: '1px'
                      // backgroundColor: 'blue'
                    }
                  }
                },
                '&.MuiOutlinedInput-root:hover': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E4E6EF',
                    borderWidth: '1px'
                    // backgroundColor: 'blue'
                  }
                }
              },

              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #e4e6ef',
                borderRadius: '0.475rem',
                '&.Mui-focused': {
                  color: '#474761'
                }
              }
            }}
            onChange={handleChange}
          //   slots={{ textField: WrappedGridEditDateInput }}
          />
        </LocalizationProvider>
        <PopoverHelper help={help} />
      </div>
      {
        !params && touch && errorMessage && <div className="invalid-feedback" style={{ display: 'block' }}>{errorMessage}</div>
      }
    </>

  );
}




