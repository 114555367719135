import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FC } from 'react';

interface YesNoModalInterface {
    dialoagAction: (opened: boolean, agree: boolean) => void,
    opened: boolean
}

export const YesNoModal: FC<YesNoModalInterface> = ({
    dialoagAction,
    opened
}) => {

  return (
    <>
      <Dialog
        open={opened}
        onClose={() => dialoagAction(false, false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Please confirm deletion!!!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dialoagAction(false, true)} color="error">Yes</Button>
          <Button onClick={() => dialoagAction(false, false)} autoFocus color="success">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}