import { FC, useEffect, useState } from 'react'
import { useLazyConnectDBQuery, useLazyConnectFunctionQuery } from '../../../app/modules/ServiceRuns/redux/cloudAppAPI'
import { useCheckQueryReady } from '../../../app/modules/ServiceRuns/hooks/RoleTableHooks'
import { useServiceRunsContext } from '../../../app/modules/ServiceRuns/context/ServiceRuns-context'

export interface CommonQueryInterface {
    field: {
        name: string,
        value: string | number | undefined | number
    },
    form: {
        touched: {},
        errors: { jsonID: { [key: string]: string[] } },
        setFieldTouched: (a: string, b: boolean) => {},
        setFieldValue: (a: string, b: string | number | Array<string | number> | { [key: string]: string }[]) => {},
        values: { serviceID: string, jsonID: { [key: string]: string } },
        initialValues: { jsonID: { [key: string]: string } }
    },
    queryparams: string,
    service_descriptive_name: string,
    commonQueryName: string,
    funcQueryName: string
}


export const CommonQuery: FC<CommonQueryInterface> = ({
    field,
    form,
    queryparams,
    service_descriptive_name,
    commonQueryName,
    funcQueryName
}) => {
    const [trigger, { data }] = useLazyConnectDBQuery();
    const [funcTrigger, { data: funcData }] = useLazyConnectFunctionQuery();
    const { queryValuesObject, queryReady } = useCheckQueryReady(form, queryparams);
    const { setCommonQueries } = useServiceRunsContext();

    useEffect(() => {

        if (queryReady && service_descriptive_name === form.values.serviceID) {
            if (funcQueryName) {
                funcTrigger({ queryParams: queryValuesObject, service: form.values.serviceID, fieldName: field.name.replace('jsonID.', ''), type: "field_config" })
            } else {
                trigger({ queryParams: queryValuesObject, service: form.values.serviceID, fieldName: field.name.replace('jsonID.', ''), type: "field_config" })
            }

        }

    }, [JSON.stringify(queryValuesObject)])


    useEffect(() => {

        setCommonQueries(prev => ({ ...prev, [commonQueryName]: [] }))

    }, [form.values.serviceID, JSON.stringify(queryValuesObject)])


    useEffect(() => {

        if (data) setCommonQueries(prev => ({ ...prev, [commonQueryName]: data.tabledata }))
        if (funcData) setCommonQueries(prev => ({ ...prev, [commonQueryName]: funcData }))

    }, [data, funcData])


    return null
}
