import { FC, useState } from 'react';
import { useServiceRunsContext } from "../../../app/modules/ServiceRuns/context/ServiceRuns-context";
import { YesNoModal } from "./YesNoModal"

export interface SubmitButtonInterface {
  form: {
    handleSubmit: () => {},
    values: { jsonID: { [key: string]: string } },
    dirty: boolean,
    setFieldValue: (a: string, b: string) => {}
  },
  state: {},
  label: string,
  isLoading: boolean,
  showInEdit: boolean,
  showInNew: boolean,
  action: string,
  color: string,
  modalConfirmation: boolean
}


export const SubmitButton: FC<SubmitButtonInterface> = ({
  label,
  state,
  isLoading = false,
  form: { handleSubmit, dirty, values, setFieldValue },
  showInEdit,
  showInNew,
  action,
  color,
  modalConfirmation
}) => {

  const { editTable, newRow, tableContentReady } = useServiceRunsContext();
  const [open, setOpen] = useState(false);
  const dialoagAction = (opened: boolean, agree: boolean) => {
    setOpen(opened);
    if (agree) handleSubmit()
  }
 

  return (
    <>
      {(((state === null && !showInEdit && !showInNew) ||
        (showInEdit && editTable && tableContentReady) ||
        (showInNew && newRow && tableContentReady))) &&
        <>
          <button
            type='button'
            className={`btn btn-color-${color ? color : "success"} btn-${color ? color : "success"} text-white btn-sm me-5`}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={() => {
              if (action) setFieldValue('jsonID.action', action);
              if (modalConfirmation) {
                setOpen(true)
              } else {
                handleSubmit();
              }
            }}
            disabled={isLoading || !dirty || action && !(values.jsonID?.rowSelection?.length > 0) ? true : false}
          >
            <div className="d-flex align-items-center">
              <span>{label}</span>
              {isLoading && (
                <span className="spinner-border spinner-border-sm text-light ms-2"></span>
              )}
            </div>
          </button>
          <YesNoModal
            dialoagAction={dialoagAction}
            opened={open}
          />
        </>
      }
    </>
  );
}